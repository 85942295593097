import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { map, Observable, withLatestFrom } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { RoutingService, URL } from '@dougs/core/routing';
import { USER_FLAG, UserStateService } from '@dougs/user/shared';

@Injectable({
  providedIn: 'root',
})
export class CanAccessOldLegalOnboardingGuard {
  constructor(
    private readonly companyStateService: CompanyStateService,
    private readonly userStateService: UserStateService,
    private readonly routingService: RoutingService,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.companyStateService.activeCompanyIdChanged$.pipe(
      withLatestFrom(this.userStateService.activeUser$),
      map(([activeCompany, activeUser]) => {
        if (activeCompany.isCreated) {
          return this.routingService.createUrl([URL.ACCOUNTING]);
        }

        if (!activeUser.flags.includes(USER_FLAG.CAN_ACCESS_OLD_LEGAL_CREATION_ONBOARDING_MODULE)) {
          return this.routingService.createUrl([URL.ONBOARDING_CREATION]);
        }

        return true;
      }),
    );
  }
}
