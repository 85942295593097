import { Injectable, Injector, ProviderToken } from '@angular/core';
import { Observable } from 'rxjs';
import { StateService } from '@dougs/core/state';
import { ModalRef } from '@dougs/ds';
import { WizardState } from '../dto';
import { WizardLocalStorage } from '../http';
import { WizardStepService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class WizardOnboardingStateService extends StateService<WizardState> {
  readonly step$: Observable<WizardState['step']> = this.select((state) => state.step);

  get modalRef(): ModalRef | undefined {
    return this.state.modalRef;
  }

  set modalRef(modalRef: ModalRef | undefined) {
    this.setState({ modalRef });
  }

  constructor(
    private readonly wizardLocalStorage: WizardLocalStorage,
    private readonly injector: Injector,
  ) {
    super();
  }

  selectStep(step: ProviderToken<WizardStepService>) {
    const injectedService = this.injector.get(step);
    this.setState({ step: injectedService });
    this.wizardLocalStorage.save(injectedService);
  }

  restore(
    restoredWizardStepIdFn: (wizardStepId: WizardStepService['id'] | null) => ProviderToken<WizardStepService>,
  ): void {
    const latestStepId = this.wizardLocalStorage.restore();
    const step = restoredWizardStepIdFn(latestStepId);
    this.selectStep(step);
  }
}
