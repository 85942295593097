import { Injectable } from '@angular/core';
import { Observable, retry } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Company } from '@dougs/company/dto';
import { OnboardingHttp } from '../http';

const waitingForAppointmentError = `waiting for legal appointment`;

@Injectable({
  providedIn: 'root',
})
export class AppointmentCheckService {
  constructor(private readonly onboardingHttpService: OnboardingHttp) {}

  timerDelay = 3; //3s
  maxDuration = 600; //10 min.

  check(company: Company): Observable<boolean> {
    return this.onboardingHttpService.checkAppointment(company).pipe(
      tap((isChecked: boolean) => {
        if (!isChecked) {
          throw waitingForAppointmentError;
        }
      }),
      retry({ delay: this.timerDelay * 1000, count: this.maxDuration / this.timerDelay }),
    );
  }
}
