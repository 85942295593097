import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGX_LOADING_BAR_IGNORED } from '@ngx-loading-bar/http-client';
import { Observable } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { AppConfig } from '@dougs/core/config';
import { Onboarding, OnboardingTransientData } from '../dto/onboarding.dto';

@Injectable({
  providedIn: 'root',
})
export class OnboardingHttp {
  constructor(private readonly http: HttpClient) {}

  getStages(company: Company): Observable<Onboarding> {
    return this.http.get<Onboarding>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${company.id}/legal-onboarding`,
    );
  }

  updateContext(company: Company, data: Onboarding): Observable<OnboardingTransientData> {
    return this.http.put<OnboardingTransientData>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${company.id}/legal-onboarding/transient-data`,
      data,
    );
  }

  completeWizard(company: Company): Observable<Onboarding> {
    return this.http.post<Onboarding>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${company.id}/legal-onboarding/complete-wizard`,
      {},
    );
  }

  checkAppointment(company: Company): Observable<boolean> {
    return this.http.get<boolean>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${company.id}/legal-onboarding/has-legal-appointment`,
      {
        context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true),
      },
    );
  }
}
