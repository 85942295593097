import { Injectable } from '@angular/core';
import { CompanyStateService } from '@dougs/company/shared';
import { LocalStorageService } from '@dougs/core/storage';
import { WizardStepService } from '../services/wizard-step.service';

@Injectable({
  providedIn: 'root',
})
export class WizardLocalStorage {
  storageKey = 'wizard' as const;

  constructor(
    public readonly localStorageService: LocalStorageService,
    public readonly companyStateService: CompanyStateService,
  ) {}

  save(state: WizardStepService): void {
    const companyId: number = this.companyStateService.activeCompany.id;
    const wizardLocalStorageState: Record<number, number> = this.localStorageService.retrieve(this.storageKey) ?? {};
    this.localStorageService.store(this.storageKey, { ...wizardLocalStorageState, [companyId]: state.id });
  }

  restore(): number | null {
    const companyId: number = this.companyStateService.activeCompany.id;
    const wizardLocalStorageState: Record<number, number> = this.localStorageService.retrieve(this.storageKey) ?? {};
    return wizardLocalStorageState[companyId] ?? null;
  }
}
