import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { Onboarding } from '../dto';
import { OnboardingHttp } from '../http';

@Injectable({
  providedIn: 'root',
})
export class OnboardingStateService extends StateService<Onboarding> {
  constructor(
    private readonly onboardingService: OnboardingHttp,
    private readonly logger: LoggerService,
    private readonly companyStateService: CompanyStateService,
  ) {
    super();
  }

  readonly onboarding$: Observable<Onboarding> = this.select((state) => state);

  get onboarding(): Onboarding {
    return this.state;
  }

  async updateTransientData(onboarding: Onboarding) {
    const company = this.companyStateService.activeCompany;
    try {
      this.setState({
        transientData: await lastValueFrom(this.onboardingService.updateContext(company, onboarding)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshOnboarding(): Promise<void> {
    const company = this.companyStateService.activeCompany;
    try {
      this.setState(await lastValueFrom(this.onboardingService.getStages(company)));
    } catch (e) {
      this.logger.error(e);
    }
  }

  async completeWizard() {
    const company = this.companyStateService.activeCompany;
    try {
      this.setState(await lastValueFrom(this.onboardingService.completeWizard(company)));
    } catch (e) {
      this.logger.error(e);
    }
  }
}
