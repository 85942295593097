import { Type } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { WizardHelp, WizardTab } from '../dto';

export abstract class WizardStepService {
  abstract readonly id: number;
  abstract readonly tab: WizardTab;
  abstract readonly help: WizardHelp;
  abstract readonly component: Type<unknown>;
  abstract formGroup: UntypedFormGroup;
  abstract readonly progressBarValue: number;

  abstract hasNextStep(): Promise<boolean>;

  abstract hasPreviousStep(): Promise<boolean>;

  abstract goToNextStep(): Promise<void>;

  abstract goToPreviousStep(): Promise<void>;
}
