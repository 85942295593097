export const ONBOARDING_WIZARD_TABS = {
  Project: {
    order: 1,
    name: 'Projet',
    isEnable: true,
    progressionValue: 5,
  },
  Company: {
    order: 2,
    name: 'Entreprise',
    isEnable: true,
    progressionValue: 35,
  },
  You: {
    order: 3,
    name: 'Vous',
    isEnable: true,
    progressionValue: 63,
  },
  FurtherInformation: {
    order: 4,
    name: 'Infos complémentaires',
    isEnable: true,
    progressionValue: 100,
  },
};
